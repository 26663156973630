<template>
  <div>
    <ul class="list">
      <li
        @dragenter="dragenter($event, index)"
        @dragover="dragover($event, index)"
        @dragstart="dragstart(index)"
        draggable
        v-for="(item, index) in list"
        :key="item.label"
        class="list-item"
      >
        <el-input></el-input>
        {{ item.label }}
        <i
          @click="handleClick"
          title="输入更多内容"
          class="el-icon-upload2"
        ></i>
      </li>
    </ul>

    <div class="content main_item mb20">
      <div class="title">
        <div>
          <span>投票内容</span>
          <span class="tip">(至少填写2个投票选项)</span>
        </div>
      </div>

      <div class="main">
        <el-card class="main_left">
          <ul class="options">
            <li
              @dragenter="dragenter($event, index)"
              @dragover="dragover($event, index)"
              @dragstart="dragstart(index)"
              draggable
              class="list-item"
              v-for="(item, index) in voting.votingTextList"
              :key="'a' + index"
            >
              <el-input
                placeholder="请填写选项名"
                :value="item.title"
              ></el-input>

              <i title="拖动调整位置" class="iconfonts icon-move"></i>
              <i
                v-if="index >= 2"
                @click="del(index)"
                title="删除该条数据"
                class="iconfonts icon-subtract"
              ></i>
            </li>
          </ul>

          <ul class="options">
            <li
              class="mb20 option_item_img"
              v-for="(item, index) in voting.votingImgList"
              :key="'a' + index"
              @dragenter="dragenter($event, index)"
              @dragover="dragover($event, index)"
              @dragstart="dragstart(index)"
              draggable
            >
              <div class="optoin_item_text">
                <el-input
                  placeholder="请填写选项名"
                  :value="item.title"
                ></el-input>
                <i title="拖动调整位置" class="iconfonts icon-move"></i>
                <i
                  v-if="index >= 2"
                  @click="del(index)"
                  title="删除该条数据"
                  class="iconfonts icon-subtract"
                ></i>
              </div>
              <div class="img_box">
                <!-- <uploadImg
                  :oldPath="addForm.breviaryImg"
                  @uploadSuccess="uploadSuccess"
                ></uploadImg> -->
                <i
                  @click="voting.richTextMask = true"
                  title="输入更多内容"
                  class="iconfonts icon-maintenance-form"
                ></i>
              </div>
            </li>
          </ul>
        </el-card>

        <div class="btn_box">
          <el-button
            @click="voting.votingType = true"
            type="primary"
            plain
            icon="el-icon-plus"
            >添加文字</el-button
          >
          <el-button
            @click="voting.votingType = false"
            type="primary"
            plain
            icon="el-icon-plus"
            >添加图文</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { label: "列表1" },
        { label: "列表2" },
        { label: "列表3" },
        { label: "列表4" },
        { label: "列表5" },
        { label: "列表6" },
      ],
      voting: {
        votingType: true,
        votingTextList: [
          { title: "1", contentData: "" },
          { title: "2", contentData: "" },
        ], // 投票内容-文字
        votingImgList: [
          { title: "3", contentData: "", img: "" },
          { title: "4", contentData: "", img: "" },
        ], // 投票内容-图文
        richTextMask: false, // 新增富文本
        detailData: "",
        batchAddMask: false,
        batchAddcontent: "",
      },
      dragIndex: "",
      enterIndex: "",
    };
  },

  methods: {
    // 拖拽开始
    dragstart(index) {
      this.dragIndex = index;
    },

    // 进入拖拽
    dragenter(e, index) {
      e.preventDefault();
      // 避免源对象触发自身的dragenter事件
      if (this.dragIndex !== index) {
        const source = this.list[this.dragIndex];
        // const source = this.voting.votingTextList[this.dragIndex];
        // this.voting.votingTextList.splice(this.dragIndex, 1);
        // this.voting.votingTextList.splice(index, 0, source);
        this.list.splice(this.dragIndex, 1);
        this.list.splice(index, 0, source);
        // 排序变化后目标对象的索引变成源对象的索引
        this.dragIndex = index;
      }
    },

    // 拖拽结束
    dragover(e, index) {
      e.preventDefault();
    },

    handleClick() {
      console.log("666");
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  list-style: none;
}
.el-icon-upload2 {
  cursor: pointer;
}
.list-item {
  cursor: move;
  width: 400px;
  background: #ea6e59;
  border-radius: 4px;
  color: #fff;
  margin-bottom: 6px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

/deep/.el-input {
  width: 200px !important;
}
.content {
  .main {
    display: flex;

    .main_left {
      flex: 2;
      margin-right: 40px;

      .options {
        list-style: none;
        padding: 0;

        .optoin_item_text {
          display: flex;
          align-items: center;

          .iconfonts {
            font-size: 20px;
            margin-left: 10px;
            color: #8c99a0;
            cursor: pointer;
          }
        }
        .icon-subtract {
          color: #e8a617 !important;
        }

        .option_item_img {
          .channel-upload,
          .avatar-uploader-icon,
          .avatar {
            width: 80px;
            height: 80px;
            line-height: 80px;
            border-radius: 8px;
          }

          .img_box {
            display: flex;
            align-items: center;
            .iconfonts {
              margin-left: 20px;
              font-size: 80px;
              cursor: pointer;
            }
          }
        }
      }

      .add_btn_box {
        display: flex;
        .el-button {
          flex: 1;
        }
      }
    }

    .btn_box {
      flex: 1;
      display: flex;
      flex-direction: column;

      .el-button {
        width: 25%;
        margin-left: 0;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
